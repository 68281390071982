<template>
<div class="security animate__animated animate__fadeInDown">
    <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ $t('security.title') }}</span>
    </div>
    <a-collapse class="mg-t-2" :accordion="true" :bordered="false" expandIconPosition="right">
      <template #expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel class="box" :style="customStyle">
        <div slot="header" class="color-white game-title">
          <span>{{$t('security.fundPass')}}</span>
        </div>
        <div class="color-white mg30">
            <a-form-item>
                <span class="label" slot="label">{{$t('security.fundPass')}}</span>
                <a-input-password class="bg-sec" size="large" ref="fundPassword" v-model="fund_password" />
            </a-form-item>
            <div class="btn-primary mg30" @click="saveFundpass">
                {{$t('common.submit')}}
            </div>
        </div>
      </a-collapse-panel>
      <a-collapse-panel class="box" :style="customStyle">
        <div slot="header" class="color-white game-title">
          <span>{{$t('security.loginPass')}}</span>
        </div>
        <div class="color-white mg30">
            <a-form-item>
                <span class="label" slot="label">{{$t('security.oldPassword')}}</span>
                <a-input-password size="large" ref="oldPassword" v-model="old_password" />
            </a-form-item>
            <a-form-item>
                <span class="label" slot="label">{{$t('security.password')}}</span>
                <a-input-password size="large" ref="password" v-model="password" />
            </a-form-item>
            <a-form-item>
                <span class="label" slot="label">{{$t('security.cpassword')}}</span>
                <a-input-password size="large" ref="cpassword" v-model="cpassword" />
            </a-form-item>
            <div class="btn-primary mg30" @click="changePassword">
                {{$t('common.submit')}}
            </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
</div>
  
</template>
<script>
export default {
  name: "Security",
  data() {
    return {
      fund_password:'',
      old_password:'',
      password:'',
      cpassword:'',
      customStyle: 'background: rgba(29,12,66, 0.5);border-radius: 16px;padding: 0;margin-bottom: 24px;border: 0;color:#fff;',

    };
  },
  components:{
  },
  methods: {
    changePassword(){
        if(!this.old_password || !this.password || !this.cpassword){
          this.$message.error(this.$t('security.cantbnull'));
          return;
        }
        if(this.password != this.cpassword){
            this.$message.error(this.$t('security.diffrentPassword'));
            return;
        }
        this.$http.call(this.$http.api.changePass,{
            data:{old_password:this.old_password, password:this.password}
        }).then(res=>{
            console.log(res);
            let resp = res.data;
            if(resp.code=='200'){
                // this.article = resp.data;
                this.$message.success(resp.data);
                this.$store.commit('setToken','');
                this.$router.go({name:'login'});
            }else{
                this.$message.error(resp.data);
            } 
            
        },res=>{
            console.log('something goes wrong...');
            console.log(res);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(()=>{
            
        });
    },
    saveFundpass(){
        if(!this.fund_password){
            this.$message.error(this.$t('security.needFundpass'));
            return;
        }
        this.$http.call(this.$http.api.fundPass,{
            data:{fundPass:this.fund_password}
        }).then(res=>{
            console.log(res);
            let resp = res.data;
            if(resp.code=='200'){
                // this.article = resp.data;
                this.$message.success(resp.data);
            }else{
                this.$message.error(resp.data);
            } 
            
        },res=>{
            console.log('something goes wrong...');
            console.log(res);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(()=>{
            
        });
    }
  },
};
</script>
<style lang="less">
.security{
    padding: 30px;
    width: 100%;
    position: relative;
}
.ant-collapse{
  background: transparent;
  text-align: left;
  .game-title{
    align-items: center;
    padding: 7px 0;
    
  }
  
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    color: white;
}

</style>
